export const environment = {
  name: 'staging',
  production: false,
  withAnimation: true,
  googleTagId: 'not-a-id-only-for-cookie-testing',
  webUrl: 'https://staging.caplock.app',
  serverUrl: 'https://staging.api.caplock.app',
  lastCompilationTime: '2025-03-26T20:32:12.541Z',
  awsRegion: 'eu-west-3',
  awsPictureBucket: 'staging-caplock-picture',
  awsPublicBucket: 'staging-caplock-public',
  userPoolId: 'eu-west-3_AKw55KPmM',
  userPoolWebClientId: '1m2et6d91780beqopu6sn6bujc',
  identityPoolId: 'eu-west-3:ad5efa2c-cfd7-45a6-86b6-8a456c6365cc',
  websocketUrl: 'wss://j329wop9y0.execute-api.eu-west-3.amazonaws.com/production/',
};
