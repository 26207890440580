import { Component, HostListener, Inject } from '@angular/core';
import { MembershipServiceInterface, ModalHelperService, ModalManagerService, TrackingService } from 'common';
import { environment } from "../environments/environment";
import { IsLoggedGuard } from './guard/is-logged.guard';

@Component({
  standalone: false,
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  displayCookieConsent: boolean;
  protected displayModalMask = false;

  constructor(private modalHelperService: ModalHelperService,
              @Inject('MembershipService') private membershipService: MembershipServiceInterface,
              private modalManagerService: ModalManagerService,
              protected isLoggedGuard: IsLoggedGuard,
              private trackingService: TrackingService,
  ) {
    this.modalHelperService.listenDisplayMask()
      .subscribe((display: boolean) => {
        this.displayModalMask = display;
      });
    // init google tag
    const requiredConsent = this.trackingService.configTracking(environment.googleTagId);
    if (requiredConsent) {
      this.displayCookieConsent = true
    }
    // url action interceptor
    const urlSearchParams = new URLSearchParams(window.location.search);
    const action = urlSearchParams.get('action');
    if (action) {
      if (action === 'mI') {
        const token = urlSearchParams.get('t');
        const invitationId = urlSearchParams.get('id');
        this.membershipService.storeInvitationData(token, invitationId);
      }
    }
  }


  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: any) {
    if (event.key === 'Escape') {
      this.modalManagerService.closeLastModalWithEscape();
    }
  }

  acceptCookies(accept: boolean) {
    this.trackingService.acceptCookies(accept, environment.googleTagId);
    this.displayCookieConsent = false;
  }
}
