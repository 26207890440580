// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-loader {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.main-loader img {
  max-width: 500px;
  padding-top: 100px;
  padding-bottom: 50px;
}
.main-loader ion-spinner {
  width: 50px;
  height: 50px;
}

.consent-cookie {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--ion-color-warning);
  padding: 10px;
  z-index: 1000;
  display: flex;
}
.consent-cookie > div:last-child {
  min-width: 300px;
  text-align: right;
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AAAA;EAEE,aAAA;EACA,sBAAA;EACA,6BAAA;EACA,mBAAA;AAAF;AAEE;EACE,gBAAA;EACA,kBAAA;EACA,oBAAA;AAAJ;AAGE;EACE,WAAA;EACA,YAAA;AADJ;;AAIA;EACE,eAAA;EACA,SAAA;EACA,OAAA;EACA,QAAA;EACA,0CAAA;EACA,aAAA;EACA,aAAA;EACA,aAAA;AADF;AAGE;EACE,gBAAA;EACA,iBAAA;AADJ","sourcesContent":[".main-loader {\n\n  display: flex;\n  flex-direction: column;\n  justify-content: space-evenly;\n  align-items: center;\n\n  img {\n    max-width: 500px;\n    padding-top: 100px;\n    padding-bottom: 50px;\n  }\n\n  ion-spinner {\n    width: 50px;\n    height: 50px;\n  }\n}\n.consent-cookie {\n  position: fixed;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  background-color: var(--ion-color-warning);\n  padding: 10px;\n  z-index: 1000;\n  display: flex;\n\n  & > div:last-child {\n    min-width: 300px;\n    text-align: right;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
